import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
export default {
  name: 'App',
  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },
  data() {
    return {
      locale: zhCn
    };
  },
  mounted() {
    document.getElementById('loader').style.display = 'none';
  },
  provide() {
    return {
      eventBus: this.eventBus
    };
  },
  methods: {}
};