export default {
  props: {
    height: {
      type: [String, Number],
      default: 200
    },
    trigger: {
      type: String
    },
    placement: {
      type: String
    },
    hideOnClick: {
      type: Boolean
    },
    max: {
      type: Number
    },
    isDot: Boolean,
    hidden: {
      type: Boolean
    },
    value: {
      type: [String, Number]
    },
    icon: {
      type: String,
      default: 'el-icon-bell'
    },
    props: {
      default() {
        return {
          user: 'user',
          is_read: 'is_read',
          content: 'content',
          time: 'time'
        };
      }
    },
    messages: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  emits: ['readMessages', 'readAll', 'viewAll'],
  methods: {
    readMessages(msg, index) {
      this.$emit('readMessages', msg, index);
    },
    readAll() {
      this.$emit('readAll');
    },
    viewAll() {
      this.$emit('viewAll');
    }
  }
};