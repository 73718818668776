import User from 'lin/model/user';
import { mapActions } from 'vuex';
import { post, put } from 'lin/plugin/axios';
import VuePictureCropper, { cropper } from 'vue-picture-cropper';
export default {
  components: {
    VuePictureCropper
  },
  props: {
    originalImage: {
      type: String
    },
    cropVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['switchCropVisible'],
  methods: {
    ...mapActions(['setUserAndState']),
    handleCrop() {
      // 获取裁剪数据
      const blob = cropper.getBlob();
      // 构造为文件对象
      const file = new File([blob], 'avatar.jpg', {
        type: 'image/jpeg'
      });
      return post('/cms/file', {
        file
      }).then(res => {
        // 清空输入框
        if (!Array.isArray(res) || res.length !== 1) {
          this.$message.error('头像上传失败, 请重试');
          return false;
        }
        return put('/cms/user/avatar', {
          avatar: res[0].path
        }).then(putRes => {
          if (putRes.code < window.MAX_SUCCESS_CODE) {
            this.$message({
              type: 'success',
              message: '更新头像成功'
            });
            this.switchStatus();
            // 触发重新获取用户信息
            return User.getInformation();
          }
          return Promise.reject(new Error('更新头像失败'));
        }).then(infoRes => {
          // eslint-disable-line
          // 尝试获取当前用户信息
          const user = infoRes;
          this.setUserAndState(user);
        });
      });
    },
    switchStatus() {
      this.$emit('switchCropVisible', false);
    }
  }
};