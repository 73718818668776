import "core-js/modules/es.array.push.js";
import { mapActions, mapMutations } from 'vuex';
import User from '@/lin/model/user';
import oauth2 from '@/model/oauth2';
import Utils from '@/lin/util/util';
import IconAntDesignGitHubFilled from '~icons/ant-design/github-filled';
import IconAntDesignQqOutlined from '~icons/ant-design/qq-outlined';
import IconAntDesignWechatOutlined from '~icons/ant-design/WechatOutlined';
import SimpleIconsGitee from '~icons/simple-icons/gitee';
export default {
  name: 'LoginRegisterDialog',
  components: {
    IconAntDesignGitHubFilled,
    IconAntDesignQqOutlined,
    SimpleIconsGitee,
    IconAntDesignWechatOutlined
  },
  data() {
    return {
      activeName: 'first',
      checkCodeBtn: {
        text: '获取验证码',
        loading: false,
        disabled: false,
        duration: 60,
        timer: null
      },
      dialogTableVisible: false,
      form: {
        username: '',
        password: '',
        nickname: '',
        phoneNumber: '',
        smsCode: ''
      },
      rules: {
        phoneNumber: [{
          required: true,
          message: '请输入中国大陆地区的手机号',
          trigger: 'blur'
        }],
        smsCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入登录密码',
          trigger: 'blur'
        }],
        nickname: [{
          required: true,
          message: '请输入昵称',
          trigger: 'blur'
        }]
      },
      headers: {
        'Google-RecaptchaToken': ''
      },
      activeIndex: 'login',
      ///index
      formLabelWidth: '120px',
      loading: false,
      externalProviders: []
    };
  },
  async created() {
    this.externalProviders = await oauth2.getExternalProviders();
  },
  methods: {
    ...mapActions(['setUserAndState']),
    handleSwitchClick() {
      this.$nextTick(() => {
        console.log(this.activeName);
        if (this.activeName === 'first') {
          //以手机登录
          this.rules.phoneNumber[0].required = true;
          this.rules.smsCode[0].required = true;
          this.rules.username[0].required = false;
          this.rules.password[0].required = false;
          this.rules.nickname[0].required = false;
        } else {
          //以用户名密码登录
          this.rules.phoneNumber[0].required = false;
          this.rules.smsCode[0].required = false;
          this.rules.username[0].required = true;
          this.rules.password[0].required = true;
          this.rules.nickname[0].required = false;
        }
      });
    },
    //获取注册短信验证码
    async getRegisterSmsCode() {
      if (!this.form.phoneNumber) {
        this.$message.error('请输入手机号');
        return false;
      }
      const reg = /^1[3-9]\d{9}$/;
      if (!reg.test(this.form.phoneNumber)) {
        this.$message.error('请输入中国大陆地区正确的手机号！');
        this.$refs.phoneNumber.focus();
        return false;
      }

      //开始发送验证码
      if (this.checkCodeBtn.duration !== 60) {
        this.checkCodeBtn.disabled = true;
      }
      this.checkCodeBtn.timer && clearInterval(this.checkCodeBtn.timer);
      this.checkCodeBtn.timer = setInterval(() => {
        const tmp = this.checkCodeBtn.duration--;
        this.checkCodeBtn.text = `${tmp}秒`;
        if (tmp <= 0) {
          clearInterval(this.checkCodeBtn.timer);
          this.checkCodeBtn.duration = 60;
          this.checkCodeBtn.text = '重新获取';
          this.checkCodeBtn.disabled = false;
        }
      }, 1000);
      var rst = await User.SendRegisterSmsCode(this.form.phoneNumber);
      this.$message.success(rst);
    },
    //获取登录短信验证码
    async getSmsCode() {
      if (!this.form.phoneNumber) {
        this.$message.error('请输入手机号');
        return false;
      }
      const reg = /^1[3-9]\d{9}$/;
      if (!reg.test(this.form.phoneNumber)) {
        this.$message.error('请输入中国大陆地区正确的手机号！');
        this.$refs.phoneNumber.focus();
        return false;
      }

      //开始发送验证码
      if (this.checkCodeBtn.duration !== 60) {
        this.checkCodeBtn.disabled = true;
      }
      this.checkCodeBtn.timer && clearInterval(this.checkCodeBtn.timer);
      this.checkCodeBtn.timer = setInterval(() => {
        const tmp = this.checkCodeBtn.duration--;
        this.checkCodeBtn.text = `${tmp}秒`;
        if (tmp <= 0) {
          clearInterval(this.checkCodeBtn.timer);
          this.checkCodeBtn.duration = 60;
          this.checkCodeBtn.text = '重新获取';
          this.checkCodeBtn.disabled = false;
        }
      }, 1000);
      var rst = await User.SendLoginSmsCode(this.form.phoneNumber);
      this.$message.success(rst);
    },
    async getCheckCode() {
      if (!this.form.email) {
        this.$message.error('请输入邮箱');
        return false;
      }
      if (!Utils.isEmail(this.form.email)) {
        this.$message.error('请输入正确的邮箱地址');
        return false;
      }
      if (this.checkCodeBtn.duration !== 10) {
        this.checkCodeBtn.disabled = true;
      }
      this.checkCodeBtn.timer && clearInterval(this.checkCodeBtn.timer);
      this.checkCodeBtn.timer = setInterval(() => {
        const tmp = this.checkCodeBtn.duration--;
        this.checkCodeBtn.text = `${tmp}秒`;
        if (tmp <= 0) {
          clearInterval(this.checkCodeBtn.timer);
          this.checkCodeBtn.duration = 10;
          this.checkCodeBtn.text = '重新获取';
          this.checkCodeBtn.disabled = false;
        }
      }, 1000);
      var email_code = await User.sendEmailCode({
        email: this.form.email,
        nickname: this.form.nickname
      });
      this.form.email_code = email_code;
    },
    show(key) {
      this.dialogTableVisible = true;
      this.activeIndex = key;

      //console.log('show',key)
      if (key === 'login') {
        //登录
        if (this.activeName === 'first') {
          //以手机登录
          this.rules.phoneNumber[0].required = true;
          this.rules.smsCode[0].required = true;
          this.rules.username[0].required = false;
          this.rules.password[0].required = false;
          this.rules.nickname[0].required = false;
        } else {
          //以用户名密码登录
          this.rules.phoneNumber[0].required = false;
          this.rules.smsCode[0].required = false;
          this.rules.username[0].required = true;
          this.rules.password[0].required = true;
          this.rules.nickname[0].required = false;
        }
      }
      if (key === 'register') {
        //注册
        this.rules.phoneNumber[0].required = true;
        this.rules.smsCode[0].required = true;
        this.rules.username[0].required = true;
        this.rules.password[0].required = true;
        this.rules.nickname[0].required = true;
      }
    },
    forgetPassword() {
      this.dialogTableVisible = false;
      this.$router.replace({
        name: 'password-reset'
      });
    },
    submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          // try {
          //   // Show reCAPTCHA badge:
          //   await this.$recaptchaLoaded();
          //   // Execute reCAPTCHA with action "login".
          //   this.headers['Google-RecaptchaToken'] = await this.$recaptcha(
          //     'login'
          //   );
          // } catch (e) {
          //   this.$message.error('验证码加载失败！');
          //   console.log(e);
          //   return;
          // }
          if (this.activeIndex == 'login') {
            if (this.activeName == 'first') {
              console.log('以手机号短信验证方式登录');
              if (this.form.phoneNumber === '') {
                this.$message.error('请输入手机号！');
                return false;
              }
              const reg = /^1[3-9]\d{9}$/;
              if (!reg.test(this.form.phoneNumber)) {
                this.$message.error('请输入中国大陆地区正确的手机号！');
                this.$refs.phoneNumber.focus();
                return false;
              }
              if (this.form.smsCode === '') {
                this.$message.error('请输入手机验证码！');
                return false;
              }
              console.log(this.form.phoneNumber);
              console.log(this.form.smsCode);

              //验证手机验证码
              this.loading = true;
              var res = await User.CheckSmsCode(this.form.phoneNumber.toString(), this.form.smsCode.toString(), this.headers).finally(() => {
                this.loading = false;
              });
              await this.loginByPhone();
            } else {
              console.log('以用户名密码方式登录');
              await this.login();
            }
          } else {
            //验证注册资料
            if (this.form.nickname === '') {
              this.$message.error('请输入昵称.');
              return false;
            }
            if (this.form.phoneNumber === '') {
              this.$message.error('请输入手机号.');
              return false;
            }
            const reg = /^1[3-9]\d{9}$/;
            if (!reg.test(this.form.phoneNumber)) {
              this.$message.error('请输入中国大陆地区正确的手机号！');
              return false;
            }

            //验证手机号是否可注册 TODO

            //验证手机短信
            if (this.form.smsCode === '') {
              this.$message.error('请输入手机短信验证码.');
              return false;
            }

            //验证密码
            if (this.form.password === '') {
              this.$message.error('请输入密码.');
              return false;
            }
            await this.register();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //以手机短信验证码登录
    async loginByPhone() {
      try {
        this.loading = true;
        await User.getTokenByPhone(this.form.phoneNumber, this.form.smsCode);
        this.dialogTableVisible = false;
        await this.getInformation();
        this.loading = false;
        const redirect = decodeURIComponent(this.$route.query.redirect || this.$route.path);
        this.$router.push(redirect + '?d=' + Utils.getRandomStr());
        this.$message.success('登录成功');
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async login() {
      try {
        this.loading = true;
        await User.getToken(this.form, this.headers);
        this.dialogTableVisible = false;
        await this.getInformation();
        this.loading = false;
        // this.$router.push("/index");
        const redirect = decodeURIComponent(this.$route.query.redirect || this.$route.path);
        this.$router.push(redirect + '?d=' + Utils.getRandomStr());
        this.$message.success('登录成功');
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    //以手机短信方式注册
    async register() {
      this.loading = true;
      var res = await User.RegisterByPhone({
        nickname: this.form.nickname,
        password: this.form.password,
        phone_number: this.form.phoneNumber,
        sms_code: this.form.smsCode
      }, this.headers).finally(() => {
        this.loading = false;
      });
      if (res.code !== 0) {
        this.$message.error(res.message);
        return;
      }
      this.form.username = this.form.phoneNumber;
      this.$message.success('注册成功');
      // // Show reCAPTCHA badge:
      // await this.$recaptchaLoaded();
      // this.headers['Google-RecaptchaToken'] = await this.$recaptcha('login');
      await this.login();
    },
    async getInformation() {
      try {
        // 尝试获取当前用户信息
        const user = await User.getPermissions();
        this.setUserAndState(user);
      } catch (e) {
        console.log(e);
      }
    },
    signin(provider) {
      window.localStorage.setItem('OAUTH_LOGIN_URL', window.location.href);
      window.open(`${process.env.VUE_APP_BASE_URL}cms/oauth2/signin?provider=${provider}&redirectUrl=${process.env.VUE_APP_CURRENT_URL}`);
      // var t = window.open(
      //   `${process.env.VUE_APP_BASE_URL}cms/oauth2/signin?provider=${provider}&redirectUrl=${process.env.VUE_APP_CURRENT_URL}`,
      //   "_blank",
      //   "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=800, height=600"
      // );
      // var n = window.setInterval(function() {
      //   (t && !t.closed) || (window.clearInterval(n), window.location.reload());
      // }, 300);
    }
  }
};