export default {
  props: {
    link: {
      type: String,
      default: 'https://github.com/TaleLin/lin-cms-vue'
    }
  },
  methods: {
    showSourceCode() {
      window.open(this.link);
    }
  }
};