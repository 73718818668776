import "core-js/modules/es.array.push.js";
import User from 'lin/model/user';
import axios from 'lin/plugin/axios';
import { mapActions, mapGetters } from 'vuex';
import defaultAvatar from '@/assets/image/user/user.png';
import Avatar from './avatar.vue';
export default {
  name: 'User',
  components: {
    Avatar
  },
  data() {
    return {
      cropImg: '',
      defaultAvatar,
      username: null,
      nickname: null,
      groupName: null,
      cropVisible: false,
      nicknameChanged: false,
      dialogFormVisible: false
    };
  },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    cropVisible(val) {
      if (!val) {
        this.cropImg = '';
      }
    }
  },
  created() {
    const {
      user
    } = this.$store.state;
    this.nickname = user !== null && user !== void 0 && user.nickname ? user.nickname : '佚名';
    this.username = user !== null && user !== void 0 && user.username ? user.username : '';
    this.groupName = user !== null && user !== void 0 && user.groupName ? user.groupName : '超级管理员';
  },
  methods: {
    ...mapActions(['loginOut', 'setUserAndState']),
    fileChange(event) {
      if (event.target.files.length !== 1) {
        return;
      }
      const imgFile = event.target.files[0];
      // 验证文件大小是否符合要求, 不大于 5M
      if (imgFile.size > 1024 * 1024 * 5) {
        this.$message.error('文件过大超过5M');
        // 清空输入框
        this.clearFileInput(this.$refs.avatarInput);
        return;
      }

      // 验证图像是否符合要求
      const imgSrc = window.URL.createObjectURL(imgFile);
      const image = new Image();
      image.src = imgSrc;
      image.onload = () => {
        const w = image.width;
        const h = image.height;
        if (w < 50) {
          this.$message.error('图像宽度过小, 请选择大于50px的图像');
          // 清空输入框
          this.clearFileInput(this.$refs.avatarInput);
          return;
        }
        if (h < 50) {
          this.$message.error('图像高度过小, 请选择大于50px的图像');
          // 清空输入框
          this.clearFileInput(this.$refs.avatarInput);
          return;
        }
        // 验证通过, 打开裁剪框
        this.cropImg = imgSrc;
        this.cropVisible = true;
      };
      image.onerror = () => {
        this.$message.error('获取本地图片出现错误, 请重试');
        // 清空输入框
        this.clearFileInput(this.$refs.avatarInput);
      };
    },
    switchCropVisible(flag) {
      this.cropVisible = flag;
    },
    changeNickname() {
      this.nicknameChanged = true;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 200);
    },
    async blur() {
      if (this.nickname) {
        const {
          user
        } = this.$store.state;
        if (this.nickname !== user.nickname && this.nickname !== '佚名') {
          axios({
            method: 'put',
            url: '/cms/user/nickname',
            data: {
              nickname: this.nickname
            },
            showBackend: true
          }).then(res => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: '更新昵称成功'
              });
              // 触发重新获取用户信息
              return User.getInformation();
            }
            this.nickname = user.nickname;
          }).then(res => {
            this.setUserAndState(res);
          });
        }
      }
      this.nicknameChanged = false;
    },
    init() {
      const {
        user
      } = this.$store.state;
      this.username = user ? user.username : '未登录';
      // this.groupName = user && user.groupName ? user.groupName : '无角色';
      this.nickname = user && user.nickname ? user.nickname : '佚名';
    },
    async outLogin() {
      await User.logout();
      this.loginOut();
      location.href = '/';
      // window.location.reload(true);
    },

    clearFileInput(ele) {
      ele.value = '';
    },
    handleCommand(command) {
      switch (command) {
        case 'dashboard':
          window.open('#/dashboard');
          break;
        case 'home':
          this.$router.push('/user/' + this.user.id + '/article');
          // window.open("/user/" + this.user.id + "/article");
          break;
        case 'settings':
          this.$router.push('/setting');
          break;
        case 'outLogin':
          this.outLogin();
          break;
      }
    }
  }
};