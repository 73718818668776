export default {
  name: 'LIcon',
  props: {
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '1.2em'
    },
    height: {
      type: String,
      default: '1.2em'
    }
  }
};